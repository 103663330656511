let showSelectionM;
let Xstart;
let Ystart;
let canvasPageMarginTop = 0;
let canvasPageMarginLeft = 0;
let mouseDown = false;
let selectedObjects = [];

// console.log(
//   "canvasPageMarginLeft, canvasPageMarginTop outside the funs",
//   canvasPageMarginLeft,
//   canvasPageMarginTop
// );
export const drawDiv = (
  event,
  showSelection,
  XstartM,
  YstartM,
  setMultiSelect,
  multiSelect,
  arrayOfBoxesDrawn,
  setArrayOfBoxesDrawn,
  arrayOfBoxesDrawnRef,
  showMenu
) => {
  mouseDown = true;
  // let selectionDiv = multiSelect;
  // setMultiSelect()

  // const [multiSelect, setMultiSelect] = useState(selectionDiv);

  // set a variable that indicates the user is drawing a selection
  // if (document.getElementById("canvas-page") !== null) {
  // canvasPageMarginTop = parseInt(
  //   window.getComputedStyle(document.getElementById("canvas-page")).marginTop
  // );
  // canvasPageMarginLeft = parseInt(
  //   window.getComputedStyle(document.getElementById("canvas-page")).marginLeft
  // );
  // }
  showSelection = true;
  showSelectionM = true;

  // save the position where the mouse click started
  Xstart = event.pageX;
  Ystart = event.pageY;

  document.getElementById("mainCanvasContainerWrapper").getBoundingClientRect();
  let Xxstart =
    event.clientX -
    event.currentTarget.getBoundingClientRect().left +
    document.getElementById("mainCanvasContainerWrapper").scrollLeft;
  let Yystart =
    event.clientY -
    event.currentTarget.getBoundingClientRect().top +
    document.getElementById("mainCanvasContainerWrapper").scrollTop;
  // let selection = multiSelect;
  setMultiSelect({ width: 0, height: 0, left: Xxstart, top: Yystart });

  // document.getElementById("mainCanvasContainer").onmousemove = mousedrag;

  document.addEventListener("onmouseup", (e) => {
    closeSelec(
      e,
      arrayOfBoxesDrawn,
      setArrayOfBoxesDrawn,
      arrayOfBoxesDrawnRef,
      multiSelect,
      setMultiSelect
    );
  });
  document.onmouseup = (e) => {
    closeSelec(
      e,
      arrayOfBoxesDrawn,
      setArrayOfBoxesDrawn,
      arrayOfBoxesDrawnRef,
      multiSelect,
      setMultiSelect,
      showMenu
    );
  };
};

export function mousedrag(
  event,
  showSelection,
  XstartM,
  YstartM,

  setMultiSelect,
  setMultiSelectedComps,
  multiSelect
) {
  if (!mouseDown) return;

  // if we are making a selection lets keep updating our select box dimensions
  // let pos1 = Xstart - event.clientX;
  // let pos2 = Ystart - event.clientY;

  let leftValue =
    event.clientX - event.currentTarget.getBoundingClientRect().left;
  let Yvalue = event.clientY - event.currentTarget.getBoundingClientRect().top;
  let dragELement = document.getElementById("drawSelectionDiv");

  if (showSelectionM === true) {
    document.getElementById("mainCanvasContainerWrapper").onClick = null;

    if (event.pageX > Xstart) {
      // left to right
      dragELement.style.width = event.pageX - Xstart + "px";
    } else {
      // right to left

      dragELement.style.width = Xstart - event.pageX + "px";
      dragELement.style.left =
        event.clientX -
        event.currentTarget.getBoundingClientRect().left +
        document.getElementById("mainCanvasContainerWrapper").scrollLeft +
        "px";
      // Xstart - event.clientX;

      //event.clientX - event.currentTarget.getBoundingClientRect().left;
    }

    if (event.pageY > Ystart) {
      dragELement.style.height = event.pageY - Ystart + "px";
    } else {
      dragELement.style.height = Ystart - event.pageY + "px";
      dragELement.style.top =
        event.clientY -
        event.currentTarget.getBoundingClientRect().top +
        document.getElementById("mainCanvasContainerWrapper").scrollTop +
        "px";
      // Ystart - event.clientY;
      // event.clientY - event.currentTarget.getBoundingClientRect().top;
    }
  }

  let selectedObjs = [];

  selectedObjects = selectedObjs;
  setMultiSelectedComps(selectedObjs);

  setMultiSelect({
    ...multiSelect,
    pointerX: event.clientX,
    pointerY: event.clientY,
    timestamp: new Date().getTime(),
  });
  // selectedObjs = new Set([selectedObjs]);
}

// export function overLap(domRect1, domRect2) {
//   // console.log("hereee", domRect1, domRect2);

//   // return (
//   //   domRect1.top <= domRect2.bottom &&
//   //   // domRect1.left <= domRect2.left &&
//   //   domRect1.bottom >= domRect2.top
//   //   //  &&
//   //   // domRect1.right >= domRect2.right
//   // );

//   return (
//     domRect1.top <= domRect2.top &&
//     domRect1.left <= domRect2.left &&
//     domRect1.bottom >= domRect2.bottom &&
//     domRect1.right >= domRect2.right
//   );
// }
export const closeSelec = (
  e,
  arrayOfBoxesDrawn,
  setArrayOfBoxesDrawn,
  arrayOfBoxesDrawnRef,
  multiSelect,
  setMultiSelect,
  showMenu,
  Xxstart,
  Yystart
) => {
  mouseDown = false;
  showSelectionM = false;
  document.getElementById("mainCanvasContainerWrapper").onmousemove = null;
  document.onmouseup = null;
  //document.getElementById("mainCanvasContainer").onClick = drawDiv;
  // document.getElementById("drawSelectionDiv").style.width = 0 + "px";
  // document.getElementById("drawSelectionDiv").style.height = 0 + "px";
  // if (selectedObjects.length > 0)
  document.getElementById("drawSelectionDiv").click("Dom");

  // document
  //   .getElementById("mainCanvasContainer")p

  //   .removeEventListener("onmousemove", mousedrag);

  let layoutDrawn = document.getElementById("drawSelectionDiv");
  let obj = {
    top: document.getElementById("drawSelectionDiv").style.top,
    left: document.getElementById("drawSelectionDiv").style.left,
    right: document.getElementById("drawSelectionDiv").style.right,
    bottom: document.getElementById("drawSelectionDiv").style.bottom,

    width: document.getElementById("drawSelectionDiv").style.width,

    height: document.getElementById("drawSelectionDiv").style.height,
    position: "absolute",
    outline: "1px dashed black",
    zIndex: arrayOfBoxesDrawn.length,
    // zoom: "27.9%",
  };
  // obj.right = parseFloat(obj.left) + parseFloat(obj.width) + "px";
  // obj.bottom = parseFloat(obj.top) + parseFloat(obj.height) + "px";
  obj.originalBoxWidth = parseFloat(obj.width) - 2 + "px";
  //console.log("onDragStop...", e);
  // if (parseFloat(obj.height) < 10 || parseFloat(obj.width) < 10) return;
  // let BoxDrawn = obj;
  // let htmlContents = document.getElementById("mainCanvasContainer").children[1]
  //   .children[1].children;
  // for (let i = 0; i < htmlContents.length; i++) {
  //   let divDimensions = htmlContents[i];

  //   if (
  //     parseFloat(BoxDrawn.top) < parseFloat(divDimensions.style.top) &&
  //     parseFloat(layoutDrawn.getClientRects()[0].bottom) >
  //       parseFloat(divDimensions.getClientRects()[0].bottom) &&
  //     parseFloat(BoxDrawn.left) < parseFloat(divDimensions.style.left) &&
  //     parseFloat(layoutDrawn.getClientRects()[0].right) >
  //       parseFloat(divDimensions.getClientRects()[0].right)
  //   ) {
  //     ;
  //     document.getElementById("drawSelectionDiv").appendChild(htmlContents[i]);
  //     obj.content = htmlContents[i].outerHTML;
  //     i--;
  //   }
  // }
  if (
    Number(obj.height.replace(/\D/g, "")) > 10 &&
    Number(obj.width.replace(/\D/g, "")) > 10
  ) {
    let tempArrayOfBoxesDrawn = JSON.parse(JSON.stringify(arrayOfBoxesDrawn));
    for (let i = 0; i < tempArrayOfBoxesDrawn?.length; i++) {
      if (
        parseFloat(obj.top) < parseFloat(tempArrayOfBoxesDrawn[i].top) &&
        parseFloat(obj.left) < parseFloat(tempArrayOfBoxesDrawn[i].left) &&
        parseFloat(obj.width) > parseFloat(tempArrayOfBoxesDrawn[i].width) &&
        parseFloat(obj.height) > parseFloat(tempArrayOfBoxesDrawn[i].height)
      ) {
        let newIndex = obj?.zIndex;
        obj.zIndex = tempArrayOfBoxesDrawn[i].zIndex;
        tempArrayOfBoxesDrawn[i].zIndex = newIndex;
      }
    }

    setArrayOfBoxesDrawn([...tempArrayOfBoxesDrawn, obj]);
    arrayOfBoxesDrawnRef.current = [...tempArrayOfBoxesDrawn, obj];

    let right = parseFloat(obj.left) + parseFloat(obj.width) + "px";
    let bottom = parseFloat(obj.top) + parseFloat(obj.height) + "px";
    showMenu(
      {
        x: parseFloat(right) + "px",
        y: parseFloat(bottom) - parseFloat(obj.height) + "px",
      },
      "onStopDraw"
    );
  } else {
    setMultiSelect({ ...multiSelect, width: "0px", height: "0px" });
  }

  document.getElementById("drawSelectionDiv").style.width = 0 + "px";
  document.getElementById("drawSelectionDiv").style.height = 0 + "px";
};

// export const pasteMultiSelectedComps = (
//   count,
//   setCount,
//   setShowPaste,
//   setVisibility,
//   layerCompData,
//   setLayerCompData,
//   copiedComponent,
//   countZindex,
//   multiSelectedComps,
//   setMultiSelectedComps,
//   componentType,
//   imageOnCanvas,
//   setSelectedComponent,
//   selectedComponent
// ) => {
//   let localCount = count;
//   let localCountZindex = countZindex;
//   let copiedObjects = JSON.parse(JSON.stringify(copiedComponent));

//   let modifiedObjects = copiedObjects.map((element, index) => {
//     let elemCSSProp = {},
//       compType = "",
//       coOrdinates = {},
//       constructObj = "";
//     elemCSSProp = Object.assign({}, element.componentStyles);
//     coOrdinates.x = elemCSSProp.left + 25;
//     coOrdinates.y = elemCSSProp.top + 50;
//     elemCSSProp.froalaText = element.froalaText;

//     // element.multiSelected = false;
//     if (element.componentType === "Text" || element.componentType === "Shape") {
//       compType = element.textComponentType.addContent;
//       elemCSSProp.textComponentType = element.textComponentType;
//       elemCSSProp.src =
//         element.triImage || element.hexImage || element.pentImage;
//       elemCSSProp.layerName =
//         element.componentType === "Shape" ? "Shape" : "Text"; // Hack
//     } else {
//       elemCSSProp.layerName = compType = element.componentType;
//     }

//     if (compType === "Image") {
//       constructObj = imageOnCanvas(
//         "fromGetAssets",
//         elemCSSProp.background,
//         coOrdinates.x,
//         coOrdinates.y,
//         elemCSSProp.filename,
//         elemCSSProp,
//         elemCSSProp.width,
//         elemCSSProp.height,
//         localCount
//       );
//     } else {
//       constructObj = componentType[compType](
//         coOrdinates,
//         null,
//         elemCSSProp,
//         localCount
//       );
//     }

//     localCount++;

//     let tempSelected = { ...constructObj };
//     tempSelected.componentStyles.lockBoolean = false;
//     setSelectedComponent(tempSelected);
//   });

//   // copiedObject.keyFrames = [];
//   // let getComponent;
//   // getComponent =
//   //   copiedObject.componentType === "Shape"
//   //     ? copiedObject?.textComponentType?.addContent
//   //     : copiedObject?.componentType;
//   // copiedObject.id = getComponent + localCount;
//   // let copiedCompStyles = { ...copiedObject.componentStyles };
//   // copiedObject.componentStyles = copiedCompStyles;
//   // copiedObject.componentStyles.left = copiedObject.componentStyles.left + 50;
//   // copiedObject.componentStyles.top = copiedObject.componentStyles.top + 25;
//   // copiedObject.componentStyles.zIndex = localCountZindex;
//   // copiedObject.multiSelected = false;

//   setCount(localCount);

//   setShowPaste(false);
//   setVisibility(false);
//   setMultiSelectedComps([]);
// };
// export const deleteMultiSelectedComps = (
//   event,
//   setOpenTimelinePopup,
//   layerCompData,
//   setLayerCompData,
//   multiSelectedComps,
//   setMultiSelectedComps,
//   animationObject,
//   setAnimationObject,
//   setSeconds,
//   setMillSeconds,
//   setSelectedComponent,
//   setVisibility
// ) => {
//   setOpenTimelinePopup({
//     popupWindow: false,
//     fromUnRedo: false,
//   });
//   const filteredComponents = layerCompData.filter(
//     (item) => !multiSelectedComps.includes(item)
//   );
//   if (event !== "fromKey" && Object.values(animationObject).length > 0) {
//     let tempAnimationObj = {};
//     for (let props in animationObject) {
//       multiSelectedComps.forEach((selectedComp) => {
//         if (props !== selectedComp.id)
//           tempAnimationObj[props] = animationObject[props];
//       });
//     }
//     setAnimationObject(tempAnimationObj);
//   }
//   if (layerCompData.length === 1) {
//     setMillSeconds(0);
//     setSeconds(0);
//   }
//   // setCompDeleted(true);
//   setSelectedComponent("compdeleted");
//   setLayerCompData(filteredComponents);
//   setVisibility(false);
//   setMultiSelectedComps([]);
// };
