export const constructNewTable = (sections) => {
  let tblfirst = document.createElement("table");
  let trFirst = tblfirst.insertRow();
  tblfirst.setAttribute("cellspacing", "0");
  tblfirst.setAttribute("cellpadding", "0");

  // very important to align in center when resized **start**
  tblfirst.setAttribute("align", "center");

  //  very important to align in center when resized **End**

  tblfirst.className = "firstChild";
  tblfirst.style.background = sections.style.backgroundColor;
  sections.childNodes.forEach((greenCol, greenColIOndex) => {
    // if greenColIOndex =0 , calulate the left value of first child soinsert td in
    if (greenColIOndex === 0) {
      let tdFirst = trFirst.insertCell();
      tdFirst.style.width = parseInt(greenCol?.style?.left) - 5 + "px"; //subtracted 10px to fit the font
      tdFirst.style.height = sections?.style?.height;
      tdFirst.className = "firstLeftGap verticalGap";
      // tdFirst.style.background = "red";
    } else {
      let tdFirst = trFirst.insertCell();
      tdFirst.style.width =
        parseInt(greenCol?.style?.left) -
        parseInt(sections.childNodes[greenColIOndex - 1].style?.left) -
        parseInt(sections.childNodes[greenColIOndex - 1].style?.width) +
        "px";
      tdFirst.style.height = sections?.style?.height;
      // tdFirst.style.background = "blue";
      tdFirst.className = "colGap verticalGap";
    }
    // occupy green col width
    let greenColTD = trFirst.insertCell();
    greenColTD.setAttribute("width", parseInt(greenCol.style.width));
    greenColTD.setAttribute("height", parseInt(greenCol.style.height));

    // greenColTD.style.background = "green";
    greenColTD.className = "stackColumn makeCol100percent";

    if (greenCol.children.length > 1) {
      sortGreenColElements(greenCol);
    }
    singleColumnIn(greenCol, greenColTD, sections);

    //Check for rightMost col
    if (greenColIOndex === sections.childNodes.length - 1) {
      let tdFirst = trFirst.insertCell();
      tdFirst.style.width =
        parseInt(sections.style?.width) -
        parseInt(greenCol?.style?.left) -
        parseInt(greenCol?.style?.width) -
        10 + //subtracted 10px to fit the font
        "px";
      tdFirst.style.height = sections?.style?.height;
      //  tdFirst.style.background = "grey";
      tdFirst.className = "last colGap verticalGap";
    }
  });

  return tblfirst;
};

const singleColumnIn = (greenCol, greenColTD, sections) => {
  //Rule 1: Every yellow elem is table
  greenCol.childNodes.forEach((yellowChild, yellowChildIndex) => {
    let tblYellow = document.createElement("table");

    tblYellow.setAttribute("cellspacing", "0");
    tblYellow.setAttribute("cellpadding", "0");
    tblYellow.setAttribute("width", "100%");
    tblYellow.className = "yellowboder";

    if (yellowChildIndex === 0) {
      if (parseInt(greenCol.style.top) > 2) {
        let trYellow = tblYellow.insertRow();
        let tdFirst = trYellow.insertCell();
        tdFirst.style.height = yellowChild.style.top;
        tdFirst.style.width = yellowChild.style.width;
        tblYellow.className = "topGapif";
        // tblYellow.style.background = "red";
      }
    } else {
      let prevEleStyle = greenCol.childNodes[yellowChildIndex - 1]?.style;
      let currentEleStyle = yellowChild.style;
      if (
        parseInt(currentEleStyle?.left) >
        parseInt(prevEleStyle?.left) + parseInt(prevEleStyle?.width)
      ) {
        groupingOfColumn(
          greenColTD,
          yellowChild,
          prevEleStyle,
          currentEleStyle,
          sections
        );
        return;
      } else {
        if (
          parseInt(yellowChild.style.top) -
            parseInt(greenCol.childNodes[yellowChildIndex - 1]?.style?.top) +
            greenCol.childNodes[yellowChildIndex - 1]?.clientHeight >
          2
        ) {
          let trYellow = tblYellow.insertRow();
          let tdFirst = trYellow.insertCell();

          tdFirst.style.height =
            parseInt(yellowChild.style.top) -
            parseInt(greenCol.childNodes[yellowChildIndex - 1]?.style?.top) -
            greenCol.childNodes[yellowChildIndex - 1]?.clientHeight +
            "px";
          tdFirst.className = "topGapElse";
          //tdFirst.style.background = "#fff";
        }
      }
    }

    // navigate inside yellow sections.
    yellowSecInsideMultiCol(yellowChild, tblYellow, sections, "TABLE");

    //insert the yellow table into green layout
    greenColTD.append(tblYellow);
  });
};

const constructCTA = (tblYellow, yellowinnerChild, elementType) => {
  // let retVal = checkLeftRightCenter(
  //   yellowinnerChild,
  //   tdParent,
  //   firstTablePadding,
  //   tbInner
  // );
  let tdtrYellowContent = addTrTdInsideTable(tblYellow, elementType);
  tdtrYellowContent.innerHTML = yellowinnerChild.childNodes[0].outerHTML;

  // yellowinnerChild.childNodes[0].style.textAlign = "center";

  // yellowinnerChild.childNodes[0].style.display = "block"; // needs confirmation from BU team
};
const yellowSecInsideMultiCol = (
  yellowChild,
  tblYellow,
  sections,
  elementType
) => {
  // navigating inside yellow section of multi col
  if (yellowChild?.childNodes[0]?.tagName === "A") {
    // constructCTA(yellowinnerChild, tdParent, firstTablePadding, tbInner);
    constructCTA(tblYellow, yellowChild, elementType);
  } else if (yellowChild?.childNodes[0]?.tagName === "IMG") {
    var imgTag = document.createElement("IMG");

    imgTag.setAttribute("src", yellowChild.childNodes[0]?.currentSrc);
    imgTag.setAttribute("width", yellowChild.childNodes[0]?.width);
    imgTag.className = "makeImage100percent";
    let tdtrYellowContent = addTrTdInsideTable(tblYellow, elementType);

    tdtrYellowContent.setAttribute("align", "center");
    tdtrYellowContent.appendChild(imgTag);
  } else {
    // if text

    if (
      yellowChild?.attributes?.blocktype?.value === "LAYOUT_TEXT" &&
      lookforParaVsline(yellowChild, tblYellow, sections)
    ) {
    } else {
      yellowChild.childNodes.forEach((insideyellow) => {
        let tdtrYellowContent = addTrTdInsideTable(tblYellow, elementType);

        tdtrYellowContent.className = "makeText100percent";
        tdtrYellowContent.innerHTML = insideyellow.innerHTML;
        tdtrYellowContent.style.fontSize = insideyellow?.style?.fontSize;
        tdtrYellowContent.style.fontWeight = insideyellow?.style?.fontWeight;
        tdtrYellowContent.style.fontFamily = insideyellow?.style?.fontFamily;
        tdtrYellowContent.style.color = insideyellow?.style?.color || "black";
        tblYellow.style.background = sections?.style?.backgroundColor;
      });
      // tdtrYellowContent.innerHTML = textcheck;
      // tdtrYellowContent.style = yellowChild.childNodes[0]?.style?.cssText;
      // tdtrYellowContent.style.removeProperty("top");
      // tdtrYellowContent.style.removeProperty("left");
      // tdtrYellowContent.style.lineHeight =
      //   parseInt(yellowChild.childNodes[0]?.style?.fontSize) + 6 + "px" ||
      //   "15px";
    }
  }
};

const lookforParaVsline = (
  yellowinnerChild,
  tbInner,
  Sections

  // cssDesktop,
  // cssMedia,
  // cssCount
) => {
  //assuming para will be greater than 300
  if (
    yellowinnerChild.childNodes?.length > 1 &&
    yellowinnerChild?.attributes?.blocktype?.value === "LAYOUT_TEXT" &&
    check4Sentences(yellowinnerChild)
  ) {
    let trInnerRow = tbInner.insertRow();
    let tdInnerTD = trInnerRow.insertCell();
    // let getValue = checkLeftRightCenter(
    //   yellowinnerChild,
    //   tdParent,
    //   firstTablePadding,
    //   tbInner
    // );

    let collectPara = "";
    yellowinnerChild.childNodes.forEach((insideyellow, indexInsideyellow) => {
      collectPara = collectPara + insideyellow.innerHTML + " ";

      // Last child and section bottom gap
      if (indexInsideyellow === yellowinnerChild.childNodes.length - 1) {
        let value =
          //  parseInt(Sections.style.top) -
          Sections.clientHeight -
          (Sections.childNodes[Sections.childNodes.length - 1].clientHeight +
            parseInt(
              Sections.childNodes[Sections.childNodes.length - 1].style.top
            ));

        let tr = tbInner.insertRow();
        let tdforTR = tr.insertCell();
        tdforTR.style.height = value + "px";
        // tdforTR.style.backgroundColor = "black";
      }
    });

    tdInnerTD.innerHTML = collectPara;

    tdInnerTD.style = yellowinnerChild.childNodes[0]?.style?.cssText;
    tdInnerTD.style.removeProperty("top");
    tdInnerTD.style.removeProperty("left");
    tdInnerTD.style.lineHeight =
      parseInt(yellowinnerChild.childNodes[0]?.style?.fontSize) + 6 + "px" ||
      "15px";
    tdInnerTD.style.background = Sections?.style?.backgroundColor;
    return tbInner;
  }
  return false;
};

function checkForSpecificCharacter(currentLine, nextLine) {
  var regex = /@|\.com|custom|Subject|userEmailAddress/g;

  // Test the sentence against the regular expression
  // if (currentLine.includes("allergic"))  ;

  return regex.test(currentLine) || regex.test(nextLine);
}
const check4Sentences = (yellowinnerChild) => {
  const lines = yellowinnerChild.childNodes;
  let firstLinePara = false;
  for (let i = 0; i < lines.length - 1; i++) {
    const currentLine = removeLastTagChar(lines[i].innerHTML.trim());

    const nextLine = removeLastTagChar(lines[i + 1].innerHTML.trim());

    const lastCharIndex = currentLine.length - 1;
    // if (currentLine.includes("allergic"))  ;
    if (
      (currentLine.charAt(lastCharIndex) === "." && /^[A-Z]/.test(nextLine)) ||
      checkForSpecificCharacter(currentLine, nextLine)
    ) {
      // if (currentLine.includes("allergic")) ;
      if (firstLinePara) {
        return false;
      } else if (
        i < 1 &&
        lines.length > 2 &&
        !checkForSpecificCharacter(currentLine, nextLine)
      )
        firstLinePara = true;
      else return false;
    }
  }
  return true;
};

function removeLastTagChar(inputString) {
  // Regular expression to match HTML tags
  const regex = /<[^>]*>/g;

  // Use replace method to remove all HTML tags
  return inputString.replace(regex, "");
}

const sortGreenColElements = (greenCol) => {
  const children = Array.from(greenCol.children);
  children.sort(
    (a, b) =>
      Number(a.getAttribute("name").match(/\d/g).join("")) -
        Number(b.getAttribute("name").match(/\d/g).join("")) &&
      parseInt(a.style.left) - parseInt(b.style.left) &&
      parseInt(a.style.top) - parseInt(b.style.top)
  );
  children.forEach((child) => greenCol.appendChild(child));
};

const groupingOfColumn = (
  greenColTD,
  yellowChild,
  prevEleStyle,
  currentEleStyle,
  sections
) => {
  let prevTable =
    greenColTD.children[greenColTD.children.length - 1].children[0];
  let prevTableRow = prevTable.children[prevTable.children.length - 1];

  let space = prevTableRow.insertCell();
  space.style.width =
    parseInt(currentEleStyle.left) -
    (parseInt(prevEleStyle?.left) + parseInt(prevEleStyle?.width)) +
    "px";

  if (yellowChild?.childNodes.length > 1) {
    let newCell = prevTableRow.insertCell();
    let newTable = document.createElement("table");
    newTable.setAttribute("cellspacing", "0");
    newTable.setAttribute("cellpadding", "0");
    newTable.setAttribute("width", "100%");
    newCell.append(newTable);
    yellowSecInsideMultiCol(yellowChild, newTable, sections, "TABLE");
  } else {
    yellowSecInsideMultiCol(yellowChild, prevTableRow, sections, "ROW");
  }
};

const addTrTdInsideTable = (tblYellow, elementType) => {
  let trYellowContent;
  let tdtrYellowContent;
  if (elementType === "ROW") {
    tdtrYellowContent = tblYellow.insertCell();
  } else {
    trYellowContent = tblYellow.insertRow();
    tdtrYellowContent = trYellowContent.insertCell();
  }
  return tdtrYellowContent;
};
