import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublishIcon from "@mui/icons-material/Publish";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import "./style.scss";
import { APPLICATION_TYPE_EDETAIL } from "../ImageLayout/Constants";

export default function Dropdown(props) {
  let appType =
    props.fileDetails?.application_type ||
    props.fileContext?.file?.application_type;
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Export
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="action-dropdown"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          value={10}
          disabled={props.disable}
          onClick={() => {
            handleClose();
            props.exportClicked();
          }}
          disableRipple
        >
          <PublishIcon />
          PUBLISH
        </MenuItem>
        <MenuItem
          value={20}
          onClick={() => {
            handleClose();
            props.downloadClicked(true);
          }}
          disabled={appType === 1}
          disableRipple
        >
          <DownloadForOfflineIcon />
          DOWNLOAD
        </MenuItem>
        <MenuItem
          value={20}
          onClick={() => {
            handleClose();
            props.downloadResponsiveHTML(true);
          }}
          disabled={appType === 1}
          disableRipple
        >
          <DownloadForOfflineIcon />
          RESPONSIVE HTML
        </MenuItem>
        {/* {props.applicationType == APPLICATION_TYPE_EDETAIL ? */}
        <MenuItem
          value={20}
          onClick={() => {
            handleClose();
            props.setIsDownloadZip(true);
          }}
          disableRipple
        >
          <DownloadForOfflineIcon />
          DOWNLOAD ZIP
        </MenuItem>
        {/* :
          null
        } */}
      </Menu>
    </div>
  );
}
