export const checkLeftRightCenter = (
  yellowinnerChild,
  td,
  firstTablePadding,
  tbInner
) => {
  let CSSDesktop = window.localStorage.getItem("CSSDesktop");
  let CSSMedia = window.localStorage.getItem("CSSMedia");
  let CSSCount = JSON.parse(window.localStorage.getItem("CSSCount"));

  let leftValue = parseInt(yellowinnerChild.style.left) - firstTablePadding;
  let rightValue =
    600 -
    parseInt(yellowinnerChild.style.left) -
    parseInt(yellowinnerChild.style.width);
  if (rightValue - leftValue < 15 && rightValue > 50 && leftValue < 300) {
    td.setAttribute("align", "center");
    tbInner.setAttribute("align", "center");
    tbInner.setAttribute("width", parseInt(yellowinnerChild.style.width));
    let centerAlignWidth = "centerAlignWidth" + CSSCount;

    let checkTextAlignValue = checkTextAlign(yellowinnerChild);
    if (checkTextAlignValue === "center") {
      let classRule = `.${centerAlignWidth}{
        text-align:center;
      }\n`;
      CSSDesktop = CSSDesktop + classRule;
    }

    let widthPercentage =
      (((600 - parseInt(yellowinnerChild.style.width)) / 2) * 100) / 600;
    let classRuleMedia = `.${centerAlignWidth}{
        width: ${Math.floor(100 - widthPercentage)}% !important;
      }\n`;
    tbInner.className = centerAlignWidth;

    CSSMedia = CSSMedia + classRuleMedia;
    CSSCount = CSSCount + 1;
  } else if (leftValue < 50) {
    // td.setAttribute("align", "left");

    tbInner.setAttribute("align", "left");

    tbInner.setAttribute("width", parseInt(yellowinnerChild.style.width));
    let leftAlignWidth = "leftAlignWidth" + CSSCount;
    td.className = "parent" + leftAlignWidth;
    let classRule = `.${"parent" + leftAlignWidth}{
      padding-left: ${leftValue}px;
      
    }\n`;
    tbInner.className = leftAlignWidth;
    let widthPercentage =
      (((600 - parseInt(yellowinnerChild.style.width)) / 2) * 100) / 600;
    let classRuleMedia = `.${leftAlignWidth}{
      width: ${Math.floor(100 - widthPercentage)}% !important;
    }\n`;

    // Maintain left padding
    let trYellow = tbInner.insertRow();
    let tdFirst = trYellow.insertCell();
    td.setAttribute("align", "left");

    CSSDesktop = CSSDesktop + classRule;
    CSSMedia = CSSMedia + classRuleMedia;
    CSSCount = CSSCount + 1;
  } else if (
    parseInt(yellowinnerChild.style.left) - firstTablePadding > 0 &&
    rightValue > 50
  ) {
    //LookFor left Gap
    // td.style.paddingLeft =

    let leftValue = parseInt(yellowinnerChild.style.left) - firstTablePadding;
    let valueLeftclass = "leftPadding" + CSSCount;
    td.className = "leftPadding" + CSSCount;
    let classRule = `.${valueLeftclass}{
      padding-left: ${leftValue}px;
    }\n`;
    let classRuleMedia = `.${valueLeftclass}{
      padding-left: ${Math.max(Math.floor(leftValue / 2) - 10, 8)}px;
    }\n`;
    CSSDesktop = CSSDesktop + classRule;
    CSSMedia = CSSMedia + classRuleMedia;

    // right padding css
    let rightValue =
      600 -
      parseInt(yellowinnerChild.style.left) -
      parseInt(yellowinnerChild.style.width);
    valueLeftclass = "rightPadding" + CSSCount;
    classRule = `
    .${valueLeftclass}{
      padding-right: ${rightValue}px;
    }\n`;
    classRuleMedia = `.${valueLeftclass}{
      padding-right: ${Math.max(Math.floor(rightValue / 2) - 10, 8)}px;
    }\n`;
    CSSDesktop = CSSDesktop + classRule;
    CSSMedia = CSSMedia + classRuleMedia;

    td.className = td.className + (" rightPadding" + CSSCount);
    //td.style.backgroundColor = "lightBlue";

    CSSCount = CSSCount + 1;
  } else if (rightValue < 50) {
    // td.setAttribute("align", "left");
    td.style.rightPadding = rightValue + "px";
    //td.setAttribute("align", "right");
    tbInner.setAttribute("align", "right");
  }
  window.localStorage.setItem("CSSDesktop", CSSDesktop);
  window.localStorage.setItem("CSSMedia", CSSMedia);
  window.localStorage.setItem("CSSCount", CSSCount);
};

const checkTextAlign = (yellowinnerChild) => {
  let returnValue = false;
  yellowinnerChild.childNodes.forEach((child) => {
    console.log(child.style.left);
    if (parseInt(child.style.left) > 10) {
      returnValue = "center";
    }
  });
  return returnValue;
};
