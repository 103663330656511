import { useEffect, useState, useContext } from "react";

import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs/dist/grapes.min.js";
import gjsBlockBasic from "grapesjs-blocks-basic";
import grapesJsexportPlugin from "grapesjs-plugin-export";
import { Box, IconButton, Button } from "@mui/material";
import "./style.scss";
import "../HtmlPreview/style.scss";
import { styleManager } from "./utils";
import GrapesJSCustomization from "../prompts/GrapesJSCustomization";
import { intermediateSave } from "../../services/api";
import FileContext from "../../context/FileContext";
import { test } from "./test";
import {
  APPLICATION_TYPE_EDETAIL,
  APPLICATION_TYPE_EMAILER,
} from "../ImageLayout/Constants";
import { toast } from "react-toastify";

function GrapejsEditor(props) {
  const [editor, setEditor] = useState({});
  const [isPopUp, setIsPopUp] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [fileDetailsToAutoSave, setFileDetailsToAutoSave] = useState();
  const fileContext = useContext(FileContext);
  // const editorRef = useRef(null);
  let modelLocalVar = "";
  // let modelTemp = [];
  let locateExactSection = "";
  window.onbeforeunload = null;

  useEffect(() => {
    if (props.fileDetails === undefined) return;
    console.log("inside useEffect props.filedetails");
    const editor = grapesjs.init({
      container: "#gjs",
      height: "95vh",
      width: "100%",
      forceClass: false,
      noticeOnUnload: 0,

      blockManager: {
        appendTo: "#blocks",
        blocks: [],
      },

      style: `
      body{
        overflow:scroll;
        font-family: 'Times New Roman';
      }
     
      ::-webkit-scrollbar
{
    width: 0px;
}
::-webkit-scrollbar-track-piece
{
    background-color: transparent;
    -webkit-outline-radius: 0px;
}
      `,
      // selectorManager: {
      //   // custom: true,
      //   // ...
      // },
      styleManager: styleManager,
      storageManager: {
        type: "remote",
        stepsBeforeSave: 3,
        contentTypeJson: true,
        storeComponents: true,
        storeStyles: true,
        storeHtml: true,
        storeCss: true,
        id: "mycustom-",
        options: {
          remote: {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + window.localStorage.getItem("userToken"),
            },
            fetchOptions: (opts) => {
              return { method: "POST" };
            },
            urlStore:
              process.env.REACT_APP_API_ENDPOINT +
              "/middleware/process/v2/intermediate-save",
            onStore: (data) => {
              let htmlString = exportHTML(editor);

              let body = {
                // console.log("props.filedEtails", props.fileDetails);
                htmlContent: htmlString,
                upscaledContent: props.upScaleImageAndFontSize(htmlString),
                layouts: [...props.arrayOfBoxesDrawnRef.current],
                uuid: props?.fileDetails?.file_conversion_pages[0]?.page_uuid,
                id: props.fileDetails?.file_conversion_pages[0]?.id,
                parent_id:
                  props.fileDetails?.file_conversion_pages[0]?.conversion_id,
              };
              // setSaveLoading(true);
              return body;
            },
          },
        },
      },
    });

    // editor.on("canvas:dragover", (DataTransfer) => {
    //   let doc = DataTransfer;
    //   //  console.log(DataTransfer, props.dataRef);
    // });
    editor.on("load", function () {
      // console.log(editor);
      editor.getWrapper().set({ droppable: false });

      let fontProperty = editor.StyleManager.getProperty(
        "typography",
        "font-family"
      );
      var list = [];
      fontProperty.set("list", list);
      list = [
        fontProperty.addOption({
          value: "Helvetica Neue,Helvetica,Arial,sans-serif",
          name: "Helvetica",
        }),

        fontProperty.addOption({ value: "sans-serif", name: "sans-serif" }),

        fontProperty.addOption({ value: "Tahoma", name: "Tahoma" }),
        fontProperty.addOption({
          value: "Verdana, Geneva, sans-serif",
          name: "Verdana",
        }),
        { value: "'Times New Roman', serif", name: "Times New Roman" },
        fontProperty.addOption({
          value: "Courier New Courier, monospace",
          name: "Courier New Courier",
        }),
        fontProperty.addOption({ value: "'Lato', sans-serif", name: "Lato" }),
        fontProperty.addOption({
          value: "'Open Sans', sans-serif",
          name: "Open Sans",
        }),
        fontProperty.addOption({
          value: "'Montserrat', sans-serif",
          name: "Montserrat",
        }),
      ];
      const fontlist = editor.StyleManager.getProperty(
        "typography",
        "font-family"
      );
      fontProperty.set("list", list);
      let fontWeight = editor.StyleManager.getProperty(
        "typography",
        "font-weight"
      );
      var fonTWeight = [];
      //fontProperty.set("list", fonTWeight);
      fonTWeight = [
        fontWeight.addOption({
          value: "bold",
          name: "bold",
        }),
        fontWeight.addOption({
          value: "normal",
          name: "normal",
        }),
      ];

      fontWeight.set("list", fonTWeight);
      editor.StyleManager.render();
    });
    editor.on("component:selected", (component) => {
      if (component.attributes.tagName === "img") return;

      let elem = window.getComputedStyle(component.getEl()).fontFamily;
      component.setStyle({ "font-family": elem, ...component.getStyle() });

      if (elem === '"Times New Roman"' || elem === "Times") {
        // console.log(elem);
        component.setStyle({
          "font-family": '"Times New Roman"',
          ...component.getStyle(),
        });
      }

      // editor.Css.setRule("#" + component.getEl().id, {
      //   "font-family": elem,
      //   width: "unset",
      // });
    });

    const calcultePositions = (dimensions, obj, index) => {
      let left =
        parseFloat(obj.getStyle().left) - parseFloat(dimensions.left) + "px";
      let top =
        parseFloat(obj.getStyle().top) - parseFloat(dimensions.top) + "px";

      return { left: left, top: top };
    };

    editor.on("canvas:dragenter", (DataTransfer, result) => {
      locateExactSection = "";
      const compEle = editor.getComponents().models;
      //  console.log(props.dataRef.current, "props.dataRef.current");
      compEle.forEach((n) => {
        if (
          //  n.attributes.tagName === "section" &&
          n.attributes.attributes.name !== props.dataRef.current
        ) {
          n.set({ droppable: false });
          let innerCompEle = n.components().models;

          innerCompEle.forEach((m) => {
            if (
              // m.attributes.tagName === "section" &&
              m.attributes.attributes.name !== props.dataRef.current
            ) {
              m.set({ droppable: false });
            } else if (
              // m.attributes.tagName === "section" &&
              m.attributes.attributes.name === props.dataRef.current
            ) {
              locateExactSection = m;
              m.set({ droppable: true });
            }
          });
        } else if (
          // n.attributes.tagName === "section" &&
          n.attributes.attributes.name === props.dataRef.current
        ) {
          n.set({ droppable: true });
          locateExactSection = n;
        }
      });
    });

    editor.on("canvas:dragend", (DataTransfer, model) => {
      // modelLocalVar[0].addStyle({ "background-color": "red" });
      let dimensions = "";
      if (modelLocalVar === "") return;
      const compEle = editor.getComponents().models;
      //  dimensions = locateExactSection.getStyle();
      var num = parseInt(props.dataRef.current.replace(/[^0-9]/g, ""));
      const canvasElem = props.arrayOfBoxesDrawnRef.current[num];

      dimensions = canvasElem;

      if (modelLocalVar !== "")
        modelLocalVar.addStyle(calcultePositions(dimensions, modelLocalVar));

      if (locateExactSection !== "")
        locateExactSection.set({ droppable: false });
      props.setData("");
      modelLocalVar = "";
      //console.log(locateExactSection);
    });

    editor.on("canvas:drop", (DataTransfer, model) => {
      //console.log("canvas:drop");
      modelLocalVar = model;

      // if (Array.isArray(model)) {
      //   modelTemp = model?.map((obj) => obj.getStyle());
      // }
    });

    setEditor(editor);
    props.usrGenHtMLRef.current = editor;

    return () => editor.destroy();
  }, [typeof props.fileDetails]); // for autosave changed this component did mount to component didupdate.
  // this use effect runs only when the filedetails changes from undefined to object on refresh for the first time.

  const intermediateSaveCall = (origin) => {
    const htmlString = exportHTML(editor, "wrapper");
    let body = {
      htmlContent: htmlString,
      upscaledContent: props.upScaleImageAndFontSize(htmlString),

      layouts: [...props.arrayOfBoxesDrawnRef.current],
      uuid: props?.fileDetails?.file_conversion_pages[0]?.page_uuid,
      id: props.fileDetails?.file_conversion_pages[0]?.id,
      parent_id: props.fileDetails?.file_conversion_pages[0]?.conversion_id,
    };
    intermediateSave(body)
      .then((response) => {
        if (origin === "useEffect") {
          props.setUploadedFileDetails(response.data);
          props.setActiveStep(3);
          props.setUpscaledHTMLUsrGnrtdPath(
            response.data.file_conversion_pages[0].scaled_html_file_path
          );
          props.setIsGJSLoading(false);
          props.getUserGeneratedHTML(htmlString);
        }
      })
      .catch((error) => {
        props.setIsGJSLoading(false);
      });
  };
  useEffect(() => {
    if (props.exportCount > 0) {
      intermediateSaveCall("useEffect");
    }
  }, [props.exportCount]);

  useEffect(() => {
    console.log(
      "generate layout useEffect..  editor?.setComponents",
      Object.keys(editor).length,
      props.genLayoutRef.current
    );
    if (props.genLayoutRef.current === true) {
      // && Object.keys(editor).length) {
      window.localStorage.removeItem("gjsProject");
      console.log("generate layout called  editor?.setComponents");
      generateLayout();
    }
  }, [props.genLayoutRef.current]);
  const findHiphen = (yellowDiv) => {
    if (yellowDiv?.childNodes?.length > 1) {
      for (let i = 0; i < yellowDiv.childNodes.length; i++) {
        if (
          yellowDiv.childNodes[i]?.textContent?.includes("-") ||
          yellowDiv.childNodes[i]?.textContent?.includes("_")
        )
          return i;
      }
    }
  };
  const getColumnsInsideYellowDiv = (yellowDiv, origin) => {
    let columns = 1;
    if (yellowDiv?.childNodes?.length > 1) {
      debugger;

      for (let j = 1; j < yellowDiv?.childNodes?.length; j++) {
        if (
          yellowDiv.childNodes[0]?.textContent.length > 2 && //dot of list
          (70 *
            (parseFloat(yellowDiv.childNodes[0].getAttribute("lineHeight")) +
              parseFloat(yellowDiv.childNodes[0].style.top))) /
            100 >
            parseFloat(yellowDiv.childNodes[j].style.top)
        ) {
          columns++;
        } else return columns;
      }
    }
    return columns;
  };
  const getHeighestLineWidthDiv = (yellowDiv) => {
    var longestline = {
      width: parseFloat(yellowDiv?.childNodes[0]?.getAttribute("linewidth")),
      fontSize: parseFloat(yellowDiv?.childNodes[0]?.style?.fontSize),
      left: parseFloat(yellowDiv?.childNodes[0]?.style?.left),
      content: yellowDiv?.childNodes[0]?.textContent,
    };

    for (let i = 0; i < yellowDiv.childNodes.length; i++) {
      let currentLineWidth = parseFloat(
        yellowDiv.childNodes[i].getAttribute("linewidth")
      );
      if (currentLineWidth > longestline.width) {
        longestline.width = currentLineWidth;
        longestline.fontSize = parseFloat(
          yellowDiv?.childNodes[i]?.style?.fontSize
        );
      }
    }
    return longestline;
  };
  const getrelativeFontSize = (longContentLine, yellowDiv) => {
    return (
      (parseFloat(yellowDiv.getAttribute("originalBoxWidth")) *
        longContentLine.fontSize) /
      (longContentLine.width + longContentLine.left)
    );
    // return (
    //   ((parseFloat(yellowDiv.getAttribute("originalBoxWidth")) +
    //     parseFloat(yellowDiv.style.left)) *
    //     longContentLine.fontSize) /
    //   (longContentLine.width + longContentLine.left)
    // );
  };
  const getBiggestFontSize = (yellowDiv) => {
    let font_size = 0;
    let htmlContentLines = yellowDiv.childNodes;
    for (let i = 0; i < yellowDiv.childNodes.length; i++) {
      if (htmlContentLines[i].tagName !== "IMG") {
        if (parseFloat(htmlContentLines[i].style.fontSize) > font_size)
          font_size = parseFloat(htmlContentLines[i].style.fontSize);
      }
    }
    return font_size;
  };
  const getRightAlignedPercentage = (yellowBox, content) => {
    //if (content.textContent.includes("userEmailAddress")) ;
    let expectedlLeft =
      parseFloat(yellowBox.style.left) + parseFloat(yellowBox.style.width);
    let actualLeft = parseFloat(content.style.left);
    let contentlRight =
      parseFloat(content.style.left) +
      parseFloat(content.getAttribute("linewidth"));
    let rightGap = (contentlRight * 100) / expectedlLeft;
    return {
      leftGap: (actualLeft * 100) / expectedlLeft,
      rightGap: 100 - rightGap,
    };
  };
  const contentExceedsParentDiv = (
    yellowDiv,
    contentSFontSize,
    newFontSize,
    originalWidth,
    index
  ) => {
    let newWidth =
      (parseFloat(originalWidth) * parseFloat(newFontSize)) /
      parseFloat(contentSFontSize);

    let contentSRight =
      parseFloat(yellowDiv.style.left) +
      parseFloat(yellowDiv.childNodes[index].style.left) +
      newWidth;
    let yellowDivsRight =
      parseFloat(yellowDiv.style.left) + parseFloat(yellowDiv.style.width);

    if (contentSRight > yellowDivsRight && contentSRight - yellowDivsRight < 50)
      return newWidth + 2;
    else return false;
  };
  const addNewFontSiwwwze = (relativeFontSize, yellowDiv) => {
    if (yellowDiv.getAttribute("fontsizecorrected") === "true") return;
    for (let i = 0; i < yellowDiv.childNodes.length; i++) {
      // if (yellowDiv.childNodes[i].innerHTML.includes("OFINIVA")) ;
      // if (
      //   relativeFontSize - parseInt(yellowDiv.childNodes[i].style.fontSize) >
      //   (parseInt(yellowDiv.childNodes[i].style.fontSize) * 30) / 100
      // ) {
      //   relativeFontSize =
      //     parseInt(yellowDiv.childNodes[i].style.fontSize) +
      //     (parseInt(yellowDiv.childNodes[i].style.fontSize) * 30) / 100;
      // }
      let rightAlignedPercentage = getRightAlignedPercentage(
        yellowDiv,
        yellowDiv.childNodes[i]
      );
      let tempRelativeFontSize = "";

      let lhsFontSize = parseFloat(yellowDiv.childNodes[i].style.fontSize);
      if (rightAlignedPercentage.rightGap < 2)
        tempRelativeFontSize = lhsFontSize;
      else if (rightAlignedPercentage.leftGap > 20)
        tempRelativeFontSize =
          lhsFontSize + lhsFontSize * (rightAlignedPercentage.rightGap / 100);
      yellowDiv.childNodes[i].style.fontSize =
        (tempRelativeFontSize ? tempRelativeFontSize : relativeFontSize) + "px";

      let newContainerWidth = contentExceedsParentDiv(
        yellowDiv,
        lhsFontSize,
        tempRelativeFontSize || relativeFontSize,
        yellowDiv.childNodes[i].getAttribute("linewidth"),
        i
      );

      if (newContainerWidth) {
        yellowDiv.style.width =
          parseFloat(yellowDiv.childNodes[i].style.left) +
          newContainerWidth +
          "px";
      }

      yellowDiv.childNodes[i].setAttribute("fontsizecorrected", true);
    }
    yellowDiv.setAttribute("fontsizecorrected", true);
  };
  const addBigFontSize = (relativeFontSize, yellowDiv) => {
    if (yellowDiv.getAttribute("fontsizecorrected") === "true") return;
    for (let i = 0; i < yellowDiv.childNodes.length; i++) {
      let lhsFontSize = parseFloat(yellowDiv.childNodes[i].style.fontSize);

      yellowDiv.childNodes[i].style.fontSize = relativeFontSize;

      let newContainerWidth = contentExceedsParentDiv(
        yellowDiv,
        lhsFontSize,
        relativeFontSize,
        yellowDiv.childNodes[i].getAttribute("linewidth"),
        i
      );

      if (newContainerWidth) {
        yellowDiv.style.width =
          parseFloat(yellowDiv.childNodes[i].style.left) +
          newContainerWidth +
          "px";
      }
      yellowDiv.childNodes[i].setAttribute("fontsizecorrected", true);
    }
    yellowDiv.setAttribute("fontsizecorrected", true);
  };
  // const getGreenDivs = () => {
  //   var body = document.createElement("body");
  //   for (let i = 0; i <= 2; i++) {
  //     var section = document.createElement(["section"]);
  //     // let top="0px";
  //     if (i > 0) {
  //       // top= currentTop- body.childNodes[i-1].top+height
  //       // left= currentLeft- body.childNodes[i-1].left+width
  //     }
  //     section.style = `top:${
  //       10 * (i * 10)
  //     }px; left:${"10px"}; width:${"100px"}; height:${"50px"}; z-index:${999}; ${"position:absolute"} ; ${"background:green"}`;
  //     body.appendChild(section);
  //   }
  //   return body.innerHTML;
  // };
  const getDomElementFromObject = (
    families,
    cloneSections,
    body,
    childSec,
    parentSction
  ) => {
    console.log("getDomElementFromObject");

    if (childSec !== "childSec") var body = document.createElement("body");
    let division = childSec !== "childSec" ? "section" : "div";

    families.forEach((family, index) => {
      var section = document.createElement([division]);

      section.style = `top:${family.top}; left:${family.left}; width:${
        family.width
      }; height:${family.height}; z-index:${
        family.zIndex
      }; ${"position:absolute"} ;`;
      // console.log(family);
      if (childSec !== "childSec") {
        section.setAttribute("draggable", false);
        section.setAttribute("name", family.name);
        if (family.blocktype) {
          section.setAttribute("child", "inner");
          section.setAttribute("originalBoxWidth", family?.originalBoxWidth);
          section.setAttribute("blocktype", family?.blocktype);
        }
      } else {
        section.setAttribute("draggable", false);
        section.setAttribute("name", family.name);
        section.setAttribute("child", "inner");
        section.setAttribute("coulumn", family?.column);
        section.setAttribute("originalBoxWidth", family?.originalBoxWidth);
        section.setAttribute("blocktype", family?.blocktype);
      }

      family.htmlContent?.forEach((content) => {
        section.appendChild(HtMLStringToDOMElement(content, family));
      });
      if (childSec === "childSec") {
        // getSectionsNewPosns(section, parentSction);
        parentSction.appendChild(section);

        // body.appendChild(parentSction);
        // parentSction = "";
        // childSec = "";
      } else {
        if (
          section?.lastChild?.tagName === "IMG" &&
          fileContext.file.application_type === APPLICATION_TYPE_EDETAIL
        ) {
          section.lastChild.style.top = section.style.top;
          section.lastChild.style.left = section.style.left;
          body.appendChild(section.lastChild);
        } else body.appendChild(section);
      }
      if (family?.children?.length) {
        let childSec = "childSec";
        getDomElementFromObject(
          family.children,
          cloneSections,
          body,
          childSec,
          section
        );
        childSec = "";
        // console.log("insertSectionInsideSection");
      }
    });

    if (
      body.childNodes.length ===
      props.arrayOfBoxesDrawnRef.current.filter((box) =>
        box?.outline?.includes("#ef9a9a")
      ).length
    ) {
      setBackgroundColor(body);
    }
    // console.log(
    //   "body.childNodes.length,redSection'sChildren, body.lastChild.childNodes.length,families",
    //   body.childNodes.length,

    //   redSections[body.childNodes.length - 1]?.children?.length,
    //   body.lastChild.childNodes.length,
    //   families.length
    // );

    //font Size correction////////////////////////////////////////////////////////////////
    if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
      let yellowDivs = body.childNodes;
      for (let i = 0; i < yellowDivs.length; i++) {
        if (!yellowDivs[i].childNodes?.length) continue;
        let columnsInsideYellowDiv = getColumnsInsideYellowDiv(yellowDivs[i]);
        // let longContentLine = getHeighestLineWidthDiv(yellowDivs[i]);
        // let relativeFontSize = getrelativeFontSize(
        //   longContentLine,
        //   yellowDivs[i]
        // );
        let relativeFontSize = getBiggestFontSize(yellowDivs[i]);

        // if (columnsInsideYellowDiv > 1)
        //   relativeFontSize /=
        //     columnsInsideYellowDiv - columnsInsideYellowDiv * 0.2;
        // console.log("addingFontSize relativeFontSize", relativeFontSize);
        // relativeFontSize = Math.round(relativeFontSize * 10) / 10;

        addBigFontSize(relativeFontSize, yellowDivs[i]);
      }
    } else if (
      cloneSections[body.childNodes.length - 1]?.children?.length ===
        body.lastChild.childNodes.length &&
      body.lastChild.childNodes.length === families.length
    ) {
      console.log("inside first big if");
      // if (body.childNodes.length === 2) ;
      let yellowDivs = body.lastChild.childNodes;

      for (let i = 0; i < yellowDivs.length; i++) {
        if (yellowDivs[i]?.childNodes?.[0]?.tagName === "IMG") continue;
        if (
          yellowDivs[i].getAttribute("coulumn") === "multi" || // columns
          yellowDivs[i]?.childNodes[0]?.getAttribute("child") === "inner"
        ) {
          debugger;
          let yellowDivsInGreen = yellowDivs[i].childNodes;
          for (let j = 0; j < yellowDivsInGreen.length; j++) {
            console.log("inside first big if, ifor, jfor");
            if (yellowDivsInGreen[j].getAttribute("child") !== "inner")
              continue;

            // let longContentLine = getHeighestLineWidthDiv(yellowDivsInGreen[j]);

            let columnsInsideYellowDiv = getColumnsInsideYellowDiv(
              yellowDivsInGreen[j],
              "insideGreen"
            );
            let relativeFontSize = getBiggestFontSize(yellowDivsInGreen[j]);
            // let relativeFontSize = getrelativeFontSize(
            //   longContentLine,
            //   yellowDivsInGreen[j]
            // );

            //   // else relativeFontSize = Math.floor(relativeFontSize);
            // }

            if (columnsInsideYellowDiv > 1) {
              relativeFontSize /= columnsInsideYellowDiv;
            }
            relativeFontSize = Math.round(relativeFontSize * 10) / 10;
            addBigFontSize(relativeFontSize, yellowDivsInGreen[j]);
          }
          // greenDivs = yellowDivs;
          // yellowDivs = greenDivs[i].childNodes;
          // i--;
          // continue;
        } else {
          //if thee text crosses the yellow bounding box, ignore the text
          if (
            yellowDivs[i].getAttribute("child") !== "inner" ||
            !yellowDivs[i].childNodes?.length
          )
            continue;
          let columnsInsideYellowDiv = getColumnsInsideYellowDiv(yellowDivs[i]);

          // let longContentLine = getHeighestLineWidthDiv(yellowDivs[i]);
          let relativeFontSize = getBiggestFontSize(yellowDivs[i]);

          // let relativeFontSize = getrelativeFontSize(
          //   longContentLine,
          //   yellowDivs[i]
          // );

          //   // else relativeFontSize = Math.floor(relativeFontSize);
          // }

          if (columnsInsideYellowDiv > 1)
            relativeFontSize /=
              columnsInsideYellowDiv - columnsInsideYellowDiv * 0.2;
          console.log("addingFontSize relativeFontSize", relativeFontSize);
          relativeFontSize = Math.round(relativeFontSize * 10) / 10;

          addBigFontSize(relativeFontSize, yellowDivs[i]);
        }
      }
    }
    // {
    //   console.log("inside first big if");
    //   // if (body.childNodes.length === 2) ;
    //   let yellowDivs = body.childNodes;
    //   for (let i = 0; i < yellowDivs.length; i++) {
    //     if (yellowDivs[i].getAttribute("blocktype") === "LAYOUT_LIST") {
    //       let yellowDivsInGreen = yellowDivs[i].childNodes;
    //       for (let j = 0; j < yellowDivsInGreen.length; j++) {
    //         console.log("inside first big if, ifor, jfor");
    //         if (yellowDivsInGreen[j].getAttribute("child") !== "inner")
    //           continue;
    //         console.log(
    //           yellowDivsInGreen[j].childNodes[1]?.textContent,
    //           "textContenttt"
    //         );

    //         let longContentLine = getHeighestLineWidthDiv(yellowDivsInGreen[j]);
    //         let columnsInsideYellowDiv = getColumnsInsideYellowDiv(
    //           yellowDivsInGreen[j],
    //           "insideGreen"
    //         );
    //         let divHashiphen = findHiphen(yellowDivsInGreen[j]);
    //         let relativeFontSize = getrelativeFontSize(
    //           longContentLine,
    //           yellowDivsInGreen[j]
    //         );
    //         if (divHashiphen !== undefined) {
    //           // if (relativeFontSize / Math.floor(relativeFontSize) >= 1.03)
    //           relativeFontSize -= 0.7;
    //           // else relativeFontSize = Math.floor(relativeFontSize);
    //         }

    //         if (columnsInsideYellowDiv > 1) {
    //           relativeFontSize /= columnsInsideYellowDiv;
    //         }
    //         relativeFontSize = Math.round(relativeFontSize * 10) / 10;
    //         addBigFontSize(relativeFontSize, yellowDivsInGreen[j]);
    //       }
    //       // greenDivs = yellowDivs;
    //       // yellowDivs = greenDivs[i].childNodes;
    //       // i--;
    //       // continue;
    //     } else {
    //       //if thee text crosses the yellow bounding box, ignore the text
    //       if (
    //         yellowDivs[i].getAttribute("child") !== "inner" ||
    //         !yellowDivs[i].childNodes?.length
    //       )
    //         continue;
    //       let columnsInsideYellowDiv = getColumnsInsideYellowDiv(yellowDivs[i]);
    //       console.log(
    //         yellowDivs[i].childNodes[1]?.textContent,
    //         "textContenttt"
    //       );
    //       let divHashiphen = findHiphen(yellowDivs[i]);
    //       let longContentLine = getHeighestLineWidthDiv(yellowDivs[i]);
    //       let relativeFontSize = getrelativeFontSize(
    //         longContentLine,
    //         yellowDivs[i]
    //       );
    //       if (divHashiphen !== undefined) {
    //         // if (relativeFontSize / Math.floor(relativeFontSize) >= 1.03)
    //         relativeFontSize -= 0.7;
    //         // else relativeFontSize = Math.floor(relativeFontSize);
    //       }

    //       if (columnsInsideYellowDiv > 1)
    //         relativeFontSize /=
    //           columnsInsideYellowDiv - columnsInsideYellowDiv * 0.2;
    //       console.log("addingFontSize relativeFontSize", relativeFontSize);
    //       relativeFontSize = Math.round(relativeFontSize * 10) / 10;

    //       addBigFontSize(relativeFontSize, yellowDivs[i]);
    //     }
    //   }
    // }
    return body.innerHTML;
  };
  function setBackgroundColor(body) {
    var childNode = body.childNodes;

    Array.from(childNode).forEach((child, index) => {
      let x = "";
      let ChildColor = "";
      Array.from(child.childNodes).forEach((node) => {
        if (node.getAttribute("blocktype") === "YOLO_CTA") {
          return;
        }
        if (node?.style?.backgroundColor?.length > 3) {
          x = node?.style?.backgroundColor;
          child.style.backgroundColor = x;
        }
        if (x === "") {
          Array.from(node.childNodes).forEach((grandChild) => {
            if (grandChild?.style?.backgroundColor?.length > 3) {
              ChildColor = grandChild.style.backgroundColor;
              child.style.backgroundColor = ChildColor;
            }

            if (ChildColor === "") {
              Array.from(grandChild.childNodes).forEach((greatChild) => {
                if (greatChild.style?.backgroundColor.length > 3) {
                  ChildColor = greatChild.style.backgroundColor;
                  child.style.backgroundColor = ChildColor;
                }
              });
            }
          });
        }
      });
    });
  }

  function buildHierarchy(objects, origin) {
    //console.log("objects.len", origin);
    for (let i = 0; i < objects.length; i++) {
      console.log("buildhierarchy inside first for");

      const parent = objects[i];
      parent.children = [];
      for (
        let j = i + 1;
        j < objects.length &&
        objects.length > 0 &&
        !objects[i + 1].outline.includes("#ef9a9a");
        j++
      ) {
        console.log("buildhierarchy inside second for");
        // if (i !== j) {
        const child = objects[j];
        if (
          parseFloat(child.left) >= parseFloat(parent.left) - 5 &&
          parseFloat(child.left) + parseFloat(child.width) <=
            parseFloat(parent.left) + parseFloat(parent.width) + 5 &&
          parseFloat(child.top) >= parseFloat(parent.top) - 5 &&
          parseFloat(child.top) + parseFloat(child.height) <=
            parseFloat(parent.top) + parseFloat(parent.height) + 5
        ) {
          console.log("buildhierarchy inside second for inside if");

          if (origin != "fromChildren") {
            child.initTop = child.top; //important to have top of initial top
            child.initLeft = child.left;
          }
          child.top = parseFloat(child.top) - parseFloat(parent.top) + "px";
          child.left = parseFloat(child.left) - parseFloat(parent.left) + "px";
          parent.children.push(child);
          // console.log("jbefore splice", j);
          objects.splice(j, 1); //
          // console.log("jafter splice", j);
          if (j > 0) j--;
        }
        // }
      }
      // If the parent has children, recursively call the function for them    if (parent.children.length > 0) {
      if (parent.children.length > 1) {
        buildHierarchy(parent.children, "fromChildren");
      }
    }
  }
  let collectClass = "";
  const canBePushed = (blocks, section) => {
    let blockIndex = [];
    for (let i = 0; i < blocks.length && blocks.length > 0; i++) {
      let parentLeft =
        parseFloat(section?.initLeft) || parseFloat(section.left);
      let parentTop = parseFloat(section?.initTop) || parseFloat(section.top);
      let parentHeight = parseFloat(section.height);
      let parentWidth = parseFloat(section.width);
      // if (!blocks[i]?.style?.width) {
      //   let contentWidth = parseFloat(
      //     blocks[i].getClientRects()[0].width.toFixed(2)
      //   );
      //   blocks[i].style.width = contentWidth + "px";
      // }
      if (
        parentLeft - 5 <= parseFloat(blocks[i].style.left) &&
        parentLeft + parentWidth + 5 >=
          parseFloat(blocks[i].style.left) +
            parseFloat(blocks[i].getClientRects()[0].width) &&
        parentTop - 5 <= parseFloat(blocks[i].style.top) &&
        parentTop + parentHeight + 5 >=
          parseFloat(blocks[i].style.top) +
            parseFloat(blocks[i].getClientRects()[0].height)
      ) {
        blocks[i].style.nTop =
          parseFloat(blocks[i].style.top) - parentTop + "px";
        blocks[i].style.nLeft =
          parseFloat(blocks[i].style.left) - parentLeft + "px";
        blocks[i].setAttribute(
          "lineWidth",
          blocks[i].getBoundingClientRect().width
        );
        blocks[i].setAttribute(
          "lineHeight",
          parseFloat(blocks[i].getBoundingClientRect().height)
        );
        if (blocks[i].outerHTML.includes("Boehringer&nbsp;Ingelheim&nbsp;"));
        blockIndex = [...blockIndex, blocks[i].outerHTML];

        // console.log(blocks, "blocksbeforSplice");
        blocks.splice(i, 1);
        i--;
      }
    }
    return blockIndex;
  };

  const pushContentsInside = (blocks, sections) => {
    let nthBlock = "";

    let blocksPushed = [];
    for (let i = 0; i < sections.length; i++) {
      if (sections[i]?.children?.length) {
        pushContentsInside(blocks, sections[i].children);
      } else {
        if (sections[i]?.outline?.includes("#00A5D6"))
          nthBlock = canBePushed(blocks, sections[i]);
        if (nthBlock?.length) {
          // pushHere();

          sections[i].htmlContent = nthBlock;
          blocksPushed = [...blocksPushed, nthBlock];
          continue;
        }
      }
      //Needs to be commented for responsiveness.,
      if (sections[i]?.outline?.includes("#00A5D6"))
        nthBlock = canBePushed(blocks, sections[i]);
      if (nthBlock.length) {
        if (!blocksPushed.includes(nthBlock)) {
          sections[i].htmlContent = nthBlock;
        }
      }
    }
  };
  const sortLayoutsLeftToRight = (layouts) => {
    //sorting Green layouts(columns) left to right
    layouts.sort((a, b) => parseFloat(a.left) - parseFloat(b.left));
  };
  const generateLayout = () => {
    // let families = [];blllhghghg
    // let children = [];
    var sectionsStructureInString = "";
    let genLayoutSucess = true;
    console.log("outside 1st if editor?.setComponents", editor);

    if (props.arrayOfBoxesDrawnRef.current.length) {
      console.log("inside 1st if editor?.setComponents", editor);
      if (props.genLayoutClicked) {
        // var divElement = HtMLStringToDOMElement(props.htmlRef.current);
        // let blocks = divElement.children[1].children[0].children;
        let blocks = Array.from(
          document.getElementsByClassName("page")[0].childNodes
        );
        let sections = JSON.parse(
          JSON.stringify(props.arrayOfBoxesDrawnRef.current)
        );
        let columnObject = [];
        sections.forEach((obj, index) => {
          sections[index].name = "section" + index;
          if (obj.outline.includes("#24f52e")) {
            obj.column = "multi";
            columnObject.push(obj);
          }
          obj.originalBoxWidth = obj.originalBoxWidth;
          obj.blocktype = obj.blocktype;
        });
        //Commented for now due to 2 column issues faced by MJML
        if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
          sections = sections.filter((section) =>
            section.outline.includes("#00A5D6")
          );
        }
        sections = sections.filter(
          (section) => !section.outline.includes("black")
        );
        sections.sort((a, b) => parseFloat(a.top) - parseFloat(b.top));
        let exit = false;

        if (columnObject.length) {
          // columnObject.forEach((obj, index) => {
          //   ;
          //   if (
          //     parseFloat(obj.top) <
          //       parseFloat(columnObject?.[index + 1]?.top) &&
          //     parseFloat(obj.top) + parseFloat(obj.height) >
          //       parseFloat(columnObject?.[index + 1]?.top) +
          //         parseFloat(columnObject?.[index + 1]?.height) &&
          //     parseFloat(obj.left) <
          //       parseFloat(columnObject?.[index + 1]?.left) &&
          //     parseFloat(obj.left) + parseFloat(obj.width) >
          //       parseFloat(columnObject?.[index + 1]?.left) +
          //         parseFloat(columnObject?.[index + 1]?.width)
          //   ) {
          //     toast.error(
          //       ` columns of outerSection${obj.sectionIndex + 1}  are nested!`
          //     );
          //     exit = true;
          //     props.genLayoutRef.current = false;
          //     props.setGenLayoutClicked(false);
          //     props.setIsGJSLoading(false);
          //   }
          // });
          // ;
          // for (let i = 0; i < columnObject.length; i++) {
          //   for (let j = 1; j < columnObject.length; j++) {
          //     if (i === columnObject.length) break;
          //     let firstBox = columnObject?.[i];
          //     let nextBox = columnObject?.[j];
          //     if (
          //       parseFloat(firstBox.top) < parseFloat(nextBox.top) &&
          //       parseFloat(firstBox.top) + parseFloat(firstBox.height) >
          //         parseFloat(nextBox.top) + parseFloat(nextBox.height) &&
          //       parseFloat(firstBox.left) < parseFloat(nextBox.left) &&
          //       parseFloat(firstBox.left) + parseFloat(firstBox.width) >
          //         parseFloat(nextBox.left) + parseFloat(nextBox.width)
          //     ) {
          //       toast.error(
          //         ` columns of outerSection${
          //           firstBox.sectionIndex + 1
          //         }  are nested!`
          //       );
          //       props.genLayoutRef.current = false;
          //       props.setGenLayoutClicked(false);
          //       props.setIsGJSLoading(false);
          //       return;
          //     }
          //   }
          // }
        }

        buildHierarchy(sections); // sections are passed /parallel
        for (let k = 0; k < sections.length; k++) {
          let section = sections[k];
          let children = section?.children;
          if (children.length) {
            if (children?.length > 1 && children[0].column === "multi")
              sortLayoutsLeftToRight(children);
          }
        }
        sections.forEach((section, index) => {
          section?.children?.forEach((children) => {
            if (children.column === "multi") {
              if (!children?.children?.length) {
                toast.error(
                  `Coulmn of outerSection ${
                    index + 1
                  } doesnot have inner layouts! `
                );
                exit = true;
              } else if (children?.children?.[0].column === "multi") {
                toast.error(
                  ` columns of outerSection ${index + 1}  are nested!`
                );
                exit = true;
              }
            }
          });
        });
        if (exit) {
          props.genLayoutRef.current = false;
          props.setGenLayoutClicked(false);
          props.setIsGJSLoading(false);
          exit = true;
          return;
        }
        pushContentsInside(blocks, sections); //push content to respective sections
        let cloneSections = JSON.parse(JSON.stringify(sections));
        //console.log("sections", sections);
        sectionsStructureInString = getDomElementFromObject(
          sections,
          cloneSections
        );
        // sectionsStructureInString = getGreenDivs();
      } else if (props.rhsHtmlForExportRef.current)
        sectionsStructureInString = props.rhsHtmlForExportRef.current;

      // console.log(sectionsStructureInString);
      // console.log("outside editor?.setComponents", editor);

      if (editor?.setComponents) {
        console.log("inside editor?.setComponents");
        editor?.DomComponents?.getWrapper()?.set("content", "");

        editor?.setComponents(sectionsStructureInString);

        props.setIsGJSLoading(false);

        setTimeout(() => {
          const compEle = editor.getComponents().models;
          compEle.forEach((n) => {
            let innerCompEle = n.components().models;
            n.set({ droppable: false, draggable: false });
            innerCompEle.forEach((m) => {
              m.set({ droppable: false, draggable: false });
              let innerMostcomp = m.components().models;
              innerMostcomp.forEach((l) => {
                l.set({ droppable: false, draggable: false });
              });
            });
          });
        }, 500);
      }
      if (sectionsStructureInString.includes("draggable")) {
        props.setIsLayoutGenerated(true);
        if (props.genLayoutClicked) intermediateSaveCall();
      }
    } else {
      if (props.clearAllLayoutClicked) {
        editor?.DomComponents?.getWrapper()?.set("content", "");
        editor?.setComponents("<section></section>");
        props.setClearAllLayoutClicked(false);
        props.setIsGJSLoading(false);
      }
    }

    props.genLayoutRef.current = false;
    props.setGenLayoutClicked(false);
    // props.setIsGJSLoading(false);
  };

  // let string = "";

  // props.arrayOfBoxesDrawnRef.forEach((element) => {
  //   string =
  //     string +
  //     `<section style='top:${element.top}; left:${element.left}; width:${
  //       element.width
  //     }; height:${element.height}; z-index:${
  //       element.zIndex
  //     }; position:absolute; padding-top:${"10px"};'></section>`;
  // });

  const HtMLStringToDOMElement = (htmlString, parent) => {
    // Create a DOMParser

    const parser = new DOMParser();

    // Parse the HTML string into a DOM element
    const doc = parser.parseFromString(htmlString, "text/html");
    let styles = doc.body.firstChild.style;

    let parentTop = parent?.initTop || parent.top;
    let parentLeft = parent.initLeft || parent.left;
    doc.body.firstChild.style.top =
      parseFloat(styles.top) - parseFloat(parentTop) + "px";
    doc.body.firstChild.style.left =
      parseFloat(styles.left) - parseFloat(parentLeft) + "px";
    // const container = document.createElement("div");
    // container.style.visibility = "hidden";
    // container.style.position = "absolute";
    // container.style.width = "0";
    // container.style.height = "0";

    // Append the parsed elements to the hidden container
    // for (const element of doc.body.children[0].children) {
    //   container.appendChild(element);
    // }

    // // Append the hidden container to the document body
    // document.body.children[0].appendChild(container);
    // // Extract the html element from the parsed document
    return doc.body.firstChild;
  };

  const exportHTML = (editor, origin) => {
    // if (editor?.getComponents()) return false;
    // APPLICATION_TYPE_EDETAIL
    const htmlString = editor.getHtml();
    const cssContent = editor.getCss();
    var divElement = props.HtMLStringToDOMElementFromExport(htmlString);

    const cssStyles = props.cssStringToCSSStyles(cssContent);
    const HTMLWithStyle = props.replaceIdsWithStyles(divElement, cssStyles);
    // Now, divElement contains

    const blob = new Blob([`${divElement.outerHTML}`], {
      type: "text/html",
    });
    // const url = URL.createObjectURL(blob);

    // // Create a link element and trigger a click event to download the file
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "exported-page.html";
    // a.click();

    // Clean up
    // URL.revokeObjectURL(url);
    let htmlContent = divElement.outerHTML;
    if (
      fileContext.file.application_type === APPLICATION_TYPE_EDETAIL &&
      origin === "wrapper"
    ) {
      if (!htmlString.includes("wrapper")) {
        const oldPart = "</head><body>";
        const newPart =
          '</head><body><div id="wrapper" style="width:1024px; height:768px; display:block; position:absolute;">';
        htmlContent = htmlContent.replace(oldPart, newPart);
        htmlContent = htmlContent.replace("</body>", "</div></body>");
      }
    }
    return htmlContent;
  };

  const dimensionsClicked = () => {
    const smDim = document.getElementsByClassName(
      "gjs-sm-sector__dimension"
    )[0];
    // if it is already open and user clicks on same options then hide it
    if (smDim.style.display === "block") {
      smDim.style.display = "none";
      setIsPopUp(false);
    } else {
      smDim.style.display = "block";
    }

    const sm = document.getElementsByClassName("gjs-sm-sector__typography")[0];
    sm.style.display = "none";

    const decorations = document.getElementsByClassName(
      "gjs-sm-sector__decorations"
    )[0];
    decorations.style.display = "none";
  };
  const typographyClicked = () => {
    const smDim = document.getElementsByClassName(
      "gjs-sm-sector__dimension"
    )[0];

    smDim.style.display = "none";
    const sm = document.getElementsByClassName("gjs-sm-sector__typography")[0];

    // if it is already open and user clicks on same options then hide it
    if (sm.style.display === "block") {
      sm.style.display = "none";
      setIsPopUp(false);
    } else {
      sm.style.display = "block";
    }

    const decorations = document.getElementsByClassName(
      "gjs-sm-sector__decorations"
    )[0];
    decorations.style.display = "none";
  };
  const appearanceClicked = () => {
    const smDim = document.getElementsByClassName(
      "gjs-sm-sector__dimension"
    )[0];
    smDim.style.display = "none";

    const sm = document.getElementsByClassName("gjs-sm-sector__typography")[0];
    sm.style.display = "none";

    const decorations = document.getElementsByClassName(
      "gjs-sm-sector__decorations"
    )[0];

    // if it is already open and user clicks on same options then hide it
    if (decorations.style.display === "block") {
      decorations.style.display = "none";
      setIsPopUp(false);
    } else {
      decorations.style.display = "block";
    }
  };

  // Example: Export button click event
  // document.getElementById("exportButton").addEventListener("click", exportHTML);

  return (
    <div id="editor" className="editor">
      <div className="gjsTopBar">
        User generated
        {"       "}
      </div>
      {/*<div className="buttonsContainer">
        <button className="customButtons" onClick={exportHTML}>
          Export HTML
        </button>
  </div>*/}
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div id="gjs"></div>
        {/*<div id="blocks"></div>*/}
        <div className="sidebarDivGrapesjs">
          <div className="IconDiv">
            <IconButton
              onClick={() => {
                setIsPopUp(true);
                // setPopupName("Dimension");
                dimensionsClicked();
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/dimensions.png")} alt="logo" />
              <div className="sideLabel">Dimension</div>
            </IconButton>
          </div>
          <div className="IconDiv">
            <IconButton
              onClick={() => {
                setIsPopUp(true);
                // setPopupName("Typography");
                typographyClicked();
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/typography.png")} alt="logo" />
              <div className="sideLabel">Typography</div>
            </IconButton>
          </div>
          <div className="IconDiv">
            <IconButton
              onClick={() => {
                setIsPopUp(true);
                // setPopupName("Appearance");
                appearanceClicked();
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/appearance.png")} alt="logo" />
              <div className="sideLabel">Appearance</div>
            </IconButton>
          </div>
          {/* <div className="IconDiv">
            <IconButton
              onClick={() => {
                setIsPopUp(true);
                setPopupName("Effects");
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/effect.png")} alt="logo" />
              <div className="sideLabel">Effects</div>
            </IconButton>
          </div> */}

          {/* {isPopUp && (
            <div className="grapesjs-editor-popover">
              <div id="stylesMangerBlock" className="stylesMangerBlock"></div>
            </div>
          )} */}

          <div
            className="grapesjs-editor-popover"
            style={{ visibility: isPopUp ? "visible" : "hidden" }}
          >
            <div id="stylesMangerBlock" className="stylesMangerBlock"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrapejsEditor;
